
import { getFoodList } from '@/api/food';
import { Vue, Component } from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import FoodDiaryForm from './foodDiaryForm.vue';

@Component({
  components: {
    FoodDiaryForm,
    Pagination,
  },
})
export default class extends Vue {
  private listQuery = {
    page: 1,
    size: 10,
    searchValue: '',
  };

  private totalElements = 0;

  private allFoodList: any[] = [];

  private foodList: any = [];

  private loading = true;

  private selectedFood = null;

  private formVisible = false;

  private mounted() {
    this.getLastDiaryMealList();
    // this.addScrollListener();
  }

  private async getFoodList() {
  try {
    const response = await getFoodList(this.listQuery);
    this.allFoodList = response.data.content;
    // this.allFoodList = this.allFoodList.concat(response.data.content);
  } catch (error) {
    console.error(error);
  } finally {
    this.loading = false;
  }
}

private getLastDiaryMealList() {
    this.loading = true;
    // this.selectedIdx = null;
    this.selectedFood = null;
    getFoodList(this.listQuery).then((res) => {
      this.loading = false;
      this.allFoodList = res.data.content;
      this.totalElements = res.data.totalElements;
    });
  }

  private handleSearch() {
    this.listQuery.page = 1;
    this.allFoodList = [];
    this.getLastDiaryMealList();
  }

  private handleVisibleForm(food: any) {
    this.formVisible = true;
    this.selectedFood = food;
  }

  private handleCloseForm() {
    this.selectedFood = null;
    this.formVisible = false;
  }

  private handleSuccessForm() {
    this.formVisible = false;
    this.$router.push({ name: 'Diary', query: { ...this.$route.query } });
  }

  private addScrollListener() {
    window.addEventListener('scroll', this.handleScroll);
  }

  private removeScrollListener() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  private handleScroll() {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const clientHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    if (scrollHeight - scrollTop <= clientHeight) {
      this.listQuery.page += 1;
      this.getFoodList();
    }
  }

  private beforeDestroy() {
    // this.removeScrollListener();
  }
}
