
import { Vue, Component, Prop } from 'vue-property-decorator';
import { addDiaryMealByFood } from '@/api/diary';

@Component({
  components: {
  },
})
export default class extends Vue {
  @Prop({ required: true }) private food!: any;

  mounted() {
    this.form = {
      weight: this.food.weightOnce,
      foodIdx: this.food.idx,
    };
  }

  private form: any = {
    foodIdx: null,
    weight: 0,
    type: this.$route.query.type,
    diaryDate: this.$route.query.diaryDate,
  }

  /* eslint-disable */
  private valueFromWeight(val: any) {
    if (!val || val === 0) return 0;
    const v1 = val / this.food.weightOnce;
    return (v1 * this.form.weight).toFixed(2);
  }
  /* eslint-enable */

  private handleAddDiaryMeal() {
    this.form = {
      ...this.form,
      foodIdx: this.food.idx,
      type: this.$route.query.type,
      diaryDate: this.$route.query.diaryDate,
    };
    addDiaryMealByFood(this.form).then(() => {
      this.$message.success('다이어리에 추가되었습니다.');
      this.$emit('success');
    });
  }
}

